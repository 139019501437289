import React from "react";
import Layout from "../Layouts/Layout";
import { ContentCard } from "../components/ContentCard";
import { Heading } from "../components/Typography";

const NotFound = () => (
  <Layout>
    <ContentCard>
      <Heading>Page not found</Heading>
    </ContentCard>
  </Layout>
);

export default NotFound;
